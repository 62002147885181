import * as React from "react";
import Header from "../common/header";
import Footer from "../common/footer";
import '../../../static/animate.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { withPrefix } from "gatsby";
import Helmet from "react-helmet";
const Layout = (props)  => {
    const {headerCustomClass, pageTitle, currentPage, breadcrumbView, lastRoute, lastRouteUrl, metaTitle, metaDescription} = props;
    return (
        <div>
            <Header headerCustomClass={headerCustomClass} pageTitle={pageTitle} currentPage={currentPage} breadcrumbView={breadcrumbView} lastRoute={lastRoute} lastRouteUrl={lastRouteUrl} />
            {props.children}
            <Footer />
            <Helmet>
                <meta charSet="utf-8" />
                <title>{metaTitle}</title>
                <meta name='description' content={metaDescription} />
                <script src={withPrefix('jquery-3.6.0.min.js')} type="text/javascript" />
                <script src={withPrefix('custom.js')} type="text/javascript" />
                <script src={withPrefix('particles.js')} type="text/javascript" />
                <script src={withPrefix('app.js')} type="text/javascript" />

                 {/*google webmaster code start here*/}

                 {/*Global site tag (gtag.js) - Google Analytics */}

                {/*<script async src="https://www.googletagmanager.com/gtag/js?id=G-12QBE5QLST"></script>*/}
                {/*<script>*/}
                {/*    window.dataLayer = window.dataLayer || [];*/}
                {/*    function gtag(){dataLayer.push(arguments);}*/}
                {/*    gtag('js', new Date());*/}

                {/*    gtag('config', 'G-12QBE5QLST');*/}
                {/*</script>*/}
                {/*google webmaster code end here*/}

                {/*schema markup  start here*/}
                {/*<script type="application/ld+json">*/}
                {/*    {*/}
                {/*        "@context": "https://schema.org",*/}
                {/*        "@type": "LocalBusiness",*/}
                {/*        "name": "GeekyBones",*/}
                {/*        "image": "",*/}
                {/*        "@id": "",*/}
                {/*        "url": "https://www.geekybones.com/",*/}
                {/*        "telephone": "+91-9478511189",*/}
                {/*        "address": {*/}
                {/*        "@type": "PostalAddress",*/}
                {/*        "streetAddress": "Plot No 986, Ground Floor, Sector 82, JLPL Industrial Area,",*/}
                {/*        "addressLocality": "Mohali",*/}
                {/*        "postalCode": "160055",*/}
                {/*        "addressCountry": "IN"*/}
                {/*    },*/}
                {/*        "openingHoursSpecification": {*/}
                {/*        "@type": "OpeningHoursSpecification",*/}
                {/*        "dayOfWeek": [*/}
                {/*        "Monday",*/}
                {/*        "Tuesday",*/}
                {/*        "Wednesday",*/}
                {/*        "Thursday",*/}
                {/*        "Friday"*/}
                {/*        ],*/}
                {/*        "opens": "10:00",*/}
                {/*        "closes": "19:00"*/}
                {/*    },*/}
                {/*        "sameAs": [*/}
                {/*        "https://www.facebook.com/GeekyBones/",*/}
                {/*        "https://twitter.com/_GeekyBones",*/}
                {/*        "https://www.instagram.com/geeky.bones/?hl=en",*/}
                {/*        ""*/}
                {/*        ]*/}
                {/*    }*/}
                {/*</script>*/}
                {/*schema markup  end here*/}
            </Helmet>
        </div>
    )
}

export default Layout;

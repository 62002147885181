import * as React from "react";
import {useForm} from "react-hook-form";


const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_NUMBER_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const ContactUsForm = () => {
    const {  register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log("Data", data);
        // alert("Form Submitted Successfully")
    }
    return (
        <div className="form form-wrap">
            <div className="row">
                <div className="col-sm-6 col-6">
                    <div className="common-input-wrap">
                        <input type="text" className="form-control contact-form-control" placeholder="Name"  {...register('Name', {required: "Name is required"} )}/>
                        {errors.Name && <span className="error-message">{errors.Name.message}</span>}
                    </div>
                </div>
                <div className="col-sm-6 col-6">
                    <div className="common-input-wrap">
                        <input type="email" className="form-control contact-form-control" placeholder="Email" {...register('Email', {required: "Email is required",pattern: {value: EMAIL_REGEX, message:"Invalid Email"}})}/>
                        {errors.Email && <span className="error-message">{errors.Email.message}</span>}
                    </div>
                </div>
                <div className="col-sm-6 col-6">
                    <div className="common-input-wrap">
                        <input type="text" className="form-control contact-form-control" placeholder="Phone" {...register('Phone', {required: "Phone Number is required",pattern: {value: PHONE_NUMBER_REGEX, message:"Invalid Phone number"}})}/>
                        {errors.Phone && <span className="error-message">{errors.Phone.message}</span>}
                    </div>
                </div>
                <div className="col-sm-6 col-6">
                    <div className="common-input-wrap">
                        <select className="form-control contact-form-control" {...register('Intrest', {required: "Interest in is required"} )} >
                            <option value={""}>Intrested In</option>
                            <option>Web development</option>
                            <option>Mobile App Development</option>
                            <option>Digital Marketing</option>
                            <option>Graphic Design</option>
                        </select>
                        {errors.Intrest && <span className="error-message">{errors.Intrest.message}</span>}
                    </div>
                </div>
                <div className="col-sm-6 col-6">
                    <div className="common-input-wrap">
                        <input type="text" className="form-control contact-form-control" placeholder="Your Budget" />
                        {errors.Budget && <span className="error-message">{errors.Budget.message}</span>}
                    </div>
                </div>
                <div className="col-sm-6 col-6">
                    <div className="common-input-wrap">
                        <input type="text" className="form-control contact-form-control" placeholder="Skype ID" />
                        {errors.SkypeID && <span className="error-message">{errors.SkypeID.message}</span>}
                    </div>
                </div>

                <div className="col-sm-12">
                    <div className="common-input-wrap">
                        <textarea name="comment" form="usrform" className="form-control contact-form-control text-area" placeholder="Message"></textarea>
                    </div>
                </div>
            </div>
            <button className="gb-animate-md-btn-red mt-4" onClick={handleSubmit(onSubmit)}> Submit </button>
        </div>
    )
}
export default ContactUsForm;

const COMMON = {
    ArrowRight: require("../../../assets/images/arrow-right.png"),
    ArrowRightDouble: require("../../../assets/images/arrow-right-double.png"),
    ArrowLeftBlack: require("../../../assets/images/arrow-left-black.png"),
    ArrowRightBlack: require("../../../assets/images/arrow-right-black.png"),
    ArrowUpWhite: require("../../../assets/images/arrow-up-white.png"),
    ArrowDownWhite: require("../../../assets/images/arrow-down-white.png"),
    ArrowUpRed: require("../../../assets/images/arrow-up-red.png"),
    Headphones: require("../../../assets/images/headphones.png"),
    Map: require("../../../assets/images/location.png"),
    Envelope: require("../../../assets/images/envelope.png"),
    EnvelopeFilled: require("../../../assets/images/envelope-filled.png"),
    FacebookWhite: require("../../../assets/images/facebook-white.png"),
    TwitterWhite: require("../../../assets/images/twitter-white.png"),
    InstagramWhite: require("../../../assets/images/instagram-white.png"),
    SkypeWhite: require("../../../assets/images/skype-white.png"),
    WhatsappWhite: require("../../../assets/images/whatsapp-white.png"),
    PlusIconWhite: require("../../../assets/images/plus-white-icon.png"),
    Send: require("../../../assets/images/send.png"),
    CommonServicesBannerBg: require("../../../assets/images/common-services-banner-bg.png"),
    CommonServicesBannerIcon: require("../../../assets/images/common-services-banner-icon.png"),
    ApproachIcon: require("../../../assets/images/approach-common-icon.png"),
    DevelopmentIcon: require("../../../assets/images/development-common-icon.png"),
    IconServices1: require("../../../assets/images/icon-services-1.png"),
    IconServices2: require("../../../assets/images/icon-services-2.png"),
    IconServices3: require("../../../assets/images/icon-services-3.png"),
    IconServices4: require("../../../assets/images/icon-services-4.png"),
    IconServices5: require("../../../assets/images/icon-services-5.png"),
    IconServices6: require("../../../assets/images/icon-services-6.png"),
    IconServices7: require("../../../assets/images/icon-services-7.png"),
    CalendarWhite: require("../../../assets/images/calendar-white.png"),
    KeyboardWhite: require("../../../assets/images/keyboard-white.png"),
    TestWhite: require("../../../assets/images/test-white.png"),
    SeoWhite: require("../../../assets/images/seo-white.png"),
    SettingsWhite: require("../../../assets/images/settings-white.png"),
    AndroidBlack: require("../../../assets/images/android-black.png"),
    ReactBlack: require("../../../assets/images/react-black.png"),
    AppleBlack: require("../../../assets/images/apple-black.png"),
    BannerDesign: require("../../../assets/images/banner-design.png"),
    BrochureDesign: require("../../../assets/images/brochure-design.png"),
    FlyerDesign: require("../../../assets/images/flyer-design.png"),
    IconDesign: require("../../../assets/images/icon-design.png"),
    IllustrationDesign: require("../../../assets/images/illustration-design.png"),
    InfographicDesign: require("../../../assets/images/infographic-design.png"),
    MobileAppDesign: require("../../../assets/images/mobile-app-design.png"),
    WebsiteDesign: require("../../../assets/images/website-design.png"),
    Zoom: require("../../../assets/images/zoom-button-icon.png"),
    star:require("../../../assets/images/star.png"),
    like:require("../../../assets/images/love.png"),
    google:require("../../../assets/images/google.png")
}
const HOME = {
    Logo: require("../../../assets/images/logo.png"),
    Bar: require("../../../assets/images/bar.png"),
    LogoWhite: require("../../../assets/images/logo-white.png"),
    CommonHeaderShape2: require("../../../assets/images/header-common-shape-2.png"),
    CommonHeaderShape4: require("../../../assets/images/header-common-shape-4.png"),
    CommonHeaderShape5: require("../../../assets/images/testimonial-shape.png"),
    CommonHeaderShape6: require("../../../assets/images/dots-box.png"),
    CommonHeaderShape12: require("../../../assets/images/temp-shape.png"),
    HomeBannerIcon: require("../../../assets/images/home-banner-icon.png"),
    OurExpert: require("../../../assets/images/our-expert.png"),
    DotsBox: require("../../../assets/images/dots-box.png"),
    DotsBox1: require("../../../assets/images/dots-box-1.png"),
    ServiceIcon1: require("../../../assets/images/service-icon1.png"),
    ServiceIcon2: require("../../../assets/images/service-icon2.png"),
    ServiceIcon3: require("../../../assets/images/service-icon3.png"),
    ServiceIcon4: require("../../../assets/images/service-icon4.png"),
    PlusIconRed: require("../../../assets/images/plus-red-icon.png"),
    CircleTick: require("../../../assets/images/circle-red-tick.png"),
    Portfolio1: require("../../../assets/images/portfolio-1.png"),
    Portfolio2: require("../../../assets/images/portfolio-2.png"),
    Portfolio3: require("../../../assets/images/portfolio-3.png"),
    Portfolio4: require("../../../assets/images/portfolio-4.png"),
    Portfolio5: require("../../../assets/images/portfolio-5.png"),
    Portfolio6: require("../../../assets/images/portfolio-6.png"),
    Portfolio7: require("../../../assets/images/portfolio-7.png"),
    Portfolio8: require("../../../assets/images/portfolio-8.png"),
    Testimonial1: require("../../../assets/images/testimonial-1.jpg"),
    Testimonial2: require("../../../assets/images/testimonial-2.jpg"),
    QuoteLeft: require("../../../assets/images/quote-left-black.png"),
    QuoteRight: require("../../../assets/images/quote-right-black.png"),
    Blog1: require("../../../assets/images/blog-1.png"),
    Blog2: require("../../../assets/images/blog-2.png"),
    BlogLarge1: require("../../../assets/images/blog-large-1.png"),
    FlutterSvg: require("../../../assets/images/flutter-svg.svg"),
    AndroidLogoHome: require("../../../assets/images/android-logo-home.webp"),
    SwiftHome: require("../../../assets/images/swift-home.png"),
    Mobile2Home: require("../../../assets/images/mobile-2-home.webp"),
    LaravelHome: require("../../../assets/images/laravel-home.png"),
    ZendHome: require("../../../assets/images/zend-home.png"),
    CodineitherHome: require("../../../assets/images/codineither-home.png"),
    CakephpHome: require("../../../assets/images/cakephp-home.png"),
    WordpressImg: require("../../../assets/images/wordpress-img.webp"),
    TechSportGear: require("../../../assets/images/tech-sports-gear.webp"),
    WordpressHome: require("../../../assets/images/wordpress-home.png"),
    DrupalHome: require("../../../assets/images/drupal-home.png"),
    MagentoHome: require("../../../assets/images/magento-home.webp"),
    WixHome: require("../../../assets/images/wix-home.png"),
    ReactHome: require("../../../assets/images/react-home.png"),
    Logo2: require("../../../assets/images/logo2.jpg"),
    ManHome: require("../../../assets/images/man.png"),
    User: require("../../../assets/images/user.png"),
    Shopify: require("../../../assets/images/shopify-green.webp"),
    OpenCart: require("../../../assets/images/opencart.png"),
}
const ABOUT = {
    AboutBanner: require("../../../assets/images/about-banner.png"),
    HowADayStart: require("../../../assets/images/how-day-start.png"),
    QuickResponse: require("../../../assets/images/quick-response.png"),
    GreatCommunication: require("../../../assets/images/great-communication.png"),
    CustomerSatisfaction: require("../../../assets/images/customer-satisfaction.png"),
    Technology1: require("../../../assets/images/technology-1.jpg"),
    Technology2: require("../../../assets/images/technology-2.jpg"),
    Technology3: require("../../../assets/images/technology-3.jpg"),
    DaishyGoyal: require("../../../assets/images/daishy-goyal.png"),
    ManojSaini: require("../../../assets/images/manoj-saini.png"),
    PreetiGhakar: require("../../../assets/images/preeti-ghakar.png"),
    RoundCicleTop: require("../../../assets/images/ah-doote-top.jpg"),
    CircleLeft: require("../../../assets/images/ah-doote-left.jpg"),
    CircleRight: require("../../../assets/images/ah-doote-right.jpg"),
    RoundCicleBottom: require("../../../assets/images/ah-doote-bottom.jpg"),
    TeamBg: require("../../../assets/images/team.jpg"),
    OurMission: require("../../../assets/images/our-mission.jpg"),
    LeaderDaishyGoyal: require("../../../assets/images/leader-daishy-goyal.png"),
    LeaderPreetiGoyal: require("../../../assets/images/leader-preeti-goyal.png"),
    LeaderManojKumar: require("../../../assets/images/leader-manoj-kumar.png"),
    LifeOfGeeky1: require("../../../assets/images/life-of-geeky-1.png"),
    LifeOfGeeky2: require("../../../assets/images/life-of-geeky-1.png"),
    LifeOfGeeky3: require("../../../assets/images/life-of-geeky-1.png"),
    Integrity: require("../../../assets/images/integrity.png"),
    TimelyDelivery: require("../../../assets/images/timely-delivery.png"),
    CustomerDelight: require("../../../assets/images/customer-delight.png"),
    WeQuantifySuccess: require("../../../assets/images/we-quantify-success.png"),
}
const SERVICES = {
    Java: require("../../../assets/images/java.png"),
    Swift: require("../../../assets/images/swift.png"),
    Flutter: require("../../../assets/images/flutter.png"),
    ReactNative: require("../../../assets/images/react-native.png"),
    PhoneGap: require("../../../assets/images/phone-gap.png"),
    Node: require("../../../assets/images/node.png"),
    Symfony: require("../../../assets/images/node.png"),
    Laravel: require("../../../assets/images/laravel.png"),
    WordPress: require("../../../assets/images/wordpress.png"),
    React: require("../../../assets/images/react-native.png"),
    MobileServices: require("../../../assets/images/mobile-services.png"),
    DesktopServices: require("../../../assets/images/desktop-services.png"),
    HybridMobileApp: require("../../../assets/images/hybrid-app.png"),
    IosApp: require("../../../assets/images/ios-app.png"),
    AndroidApp: require("../../../assets/images/android-app.png"),
    WhatWeIcon1: require("../../../assets/images/what-we-do-icon-1.png"),
    WhatWeIcon2: require("../../../assets/images/what-we-do-icon-2.png"),
    WhatWeIcon3: require("../../../assets/images/what-we-do-icon-3.png"),
    WhatWeIcon4: require("../../../assets/images/what-we-do-icon-4.png"),
    Accepted: require("../../../assets/images/accepted.png"),
    AndroidLogo: require("../../../assets/images/android-logo-2.png"),
    AppleLogo: require("../../../assets/images/apple-logo-2.png"),
    IonicLogo: require("../../../assets/images/ionic-logo.png"),
    FlutterLogo: require("../../../assets/images/flutter-logo-3.png"),
    ReactLogo: require("../../../assets/images/react-logo-with-text.png"),
    MobileAppService1: require("../../../assets/images/mobile-app-service-1.png"),
    MobileAppService2: require("../../../assets/images/mobile-app-service-2.png"),
    MobileAppService3: require("../../../assets/images/mobile-app-service-3.png"),
    MobileAppService4: require("../../../assets/images/mobile-app-service-4.png"),
    MobileAppService5: require("../../../assets/images/mobile-app-service-5.png"),
    MobileAppService6: require("../../../assets/images/mobile-app-service-6.png"),
    MobileImage1: require("../../../assets/images/mobile-1.jpg"),
    MobileImage2: require("../../../assets/images/mobile-2.jpg"),
    SliderArrowLeft: require("../../../assets/images/left-arrow.png"),
    SliderArrowRight: require("../../../assets/images/right-arrow.png"),
    SliderArrowLeftWhite: require("../../../assets/images/left-arrow-white.png"),
    SliderArrowRightWhite: require("../../../assets/images/right-arrow-white.png"),
    Check: require("../../../assets/images/check.png"),
    Graphic1: require("../../../assets/images/graphic-1.jpg"),
    Graphic2: require("../../../assets/images/graphic-2.jpg"),
    Tools: require("../../../assets/images/tools.png"),
    WebDesignServices: require("../../../assets/images/web-design-services.png"),
    LaravelServices: require("../../../assets/images/laravel-services.png"),
    NodejsServices: require("../../../assets/images/node-js-services.png"),
    WordpressServices: require("../../../assets/images/wordpress-services.png"),
    SymfonyServices: require("../../../assets/images/symfony-services.png"),
    WebDevelopmentServices: require("../../../assets/images/web-development-services.png"),
    MobileAppServices: require("../../../assets/images/mobile-app-services.png"),
    GraphicDesignServices: require("../../../assets/images/graphic-design-services.png"),
    DigitalMarketingServices: require("../../../assets/images/digital-marketing-services.png"),
    CloudBlack: require("../../../assets/images/cloud-black.png"),
    BikeGif: require("../../../assets/images/bike.gif"),
}
const PORTFOLIO = {
    Project1: require("../../../assets/images/project-1.png"),
    Project2: require("../../../assets/images/project-2.png"),
    Project3: require("../../../assets/images/project-3.png"),
    SymfonyBlack: require("../../../assets/images/php-symfony.png"),
    LaravelLogo: require("../../../assets/images/laravel-logo.png"),
    CodelgniterLogo: require("../../../assets/images/codelgniter-logo.png"),
    CakePhpLogo: require("../../../assets/images/cakephp-logo.png"),
    ReactLogo: require("../../../assets/images/react-logo.png"),
    ShopifyLogo: require("../../../assets/images/shopify-logo.png"),
    WordpressLogo: require("../../../assets/images/wordpress-logo.png"),
    SymfonyProj1: require("../../../assets/images/symfony-pro-1.jpg"),
    SymfonyProj2: require("../../../assets/images/symfony-pro-2.jpg"),
    SymfonyProj3: require("../../../assets/images/symfony-pro-3.jpg"),
    SymfonyProj4: require("../../../assets/images/symfony-pro-4.jpg"),
    IosAppDevelopmentBanner: require("../../../assets/images/ios-apps.jpg"),
    IosWork: require("../../../assets/images/ios-work.png"),
    AndroidAppDevelopmentBanner: require("../../../assets/images/android-apps.png"),
    AndroidWork: require("../../../assets/images/android-work.png"),
    ReactNativeAppDevelopmentBanner: require("../../../assets/images/react-native-apps.png"),
    ReactNativeWork: require("../../../assets/images/react-native-work.png"),
    ReactTestImage: require("../../../assets/images/react-man-test.jpeg"),
    SymfonyProject: require("../../../assets/images/symfony-project.jpg"),
    IosProject: require("../../../assets/images/ios-project.png"),
    AndroidProject: require("../../../assets/images/android-project.png"),
    AllInOne:require('../../../assets/images/all-in-one.png'),
    SportGear:require('../../../assets/images/sport-gear.png'),
    SportGearDetails:require('../../../assets/images/sport-gear-details.png'),
    Mavrik:require('../../../assets/images/mavrik.png'),
    Freshy:require('../../../assets/images/freshy.png'),
    TadPole:require("../../../assets/images/tadpole.png"),
    Auslankmatrimony:require('../../../assets/images/auslankmatrimony.png'),
    QualityMindApp:require('../../../assets/images/quality-mind.png'),
    CandyCoin:require('../../../assets/images/candy-coin.png'),
    TadpoleRides:require('../../../assets/images/tadpole-app.png'),
    RxTro:require("../../../assets/images/rxtro.png"),
    FreshyApp:require("../../../assets/images/freshy-app.png"),
    TadpoleApp:require("../../../assets/images/tadpole-app.png"),
    MeetingManagmentApp:require("../../../assets/images/meeting-managment-app.png"),
    VegatoApp:require("../../../assets/images/vegato-app.png"),
    SideffectApp:require("../../../assets/images/sideffect-app.png"),
    GraphicEcommerceDesign:require("../../../assets/images/graphic-ecommerce-design.png"),
    GraphicDesign1:require("../../../assets/images/graphic-design-1.png"),
    GraphicDesign2:require("../../../assets/images/graphic-design-2.png"),
    GraphicDesign3:require("../../../assets/images/graphic-design-3.png"),
    GraphicDesign4:require("../../../assets/images/graphic-design-4.png"),
    GraphicDesign5:require("../../../assets/images/graphic-design-5.png"),
    GraphicDesign6:require("../../../assets/images/graphic-design-6.png"),
    GraphicDesign7:require("../../../assets/images/graphic-design-7.png"),
    GraphicDesign8:require("../../../assets/images/graphic-design-8.png"),
    GraphicDesign9:require("../../../assets/images/graphic-design-9.png"),

}
const CONTACTUS = {
    MessengerGrey: require("../../../assets/images/messenger-grey.png"),
    WhatsappGrey: require("../../../assets/images/whatsapp-grey.png"),
    SkypeGrey: require("../../../assets/images/skype-grey.png"),
    GmailGrey: require("../../../assets/images/gmail-grey.png"),
}
const BLOG = {
    EyeGrey: require("../../../assets/images/eye-grey.png"),
    CalendarGrey: require("../../../assets/images/calendar-grey.png"),
    ChatGrey: require("../../../assets/images/chat-grey.png"),
    InternetOfThings: require("../../../assets/images/internet-of-things.jpg"),
    RightCompanyToJoin: require("../../../assets/images/right-company-to-join.jpg"),
    StatisticsAndAnalytics: require("../../../assets/images/statistics-and-analytics.jpg"),
    Blog1: require("../../../assets/images/blog-1.jpg"),
    RelatedImg: require("../../../assets/images/related-img.jpg"),
    IotUse: require("../../../assets/images/iot-use.jpg"),
    IotIndustryBannerUse: require("../../../assets/images/iot-industry-banner-use.jpg"),
    IotUses: require("../../../assets/images/iot-uses.jpg"),
    AreYouGoingTolearnSomethingnew: require("../../../assets/images/are-you-going-to-learn-something-new.jpg"),
    BlogDemand:require("../../../assets/images/blog-demand.png"),
    BlogEcommerce:require("../../../assets/images/blog-ecommerce-.png"),
    BlogEducation:require("../../../assets/images/blog-education.png"),
    BlogHealth:require("../../../assets/images/blog-health.png"),
    BlogFood:require("../../../assets/images/blog-food.gif"),
    BlogBanking:require("../../../assets/images/blog-banking.png"),
    PaginationArrowLeft:require("../../../assets/images/left-arrow-white.png"),
    PaginationArrowRight:require("../../../assets/images/right-arrow-white.png"),
    BlogEcommerce1:require("../../../assets/images/blogs/ecommerce-app-img-1.png"),
    BlogEcommerce2:require("../../../assets/images/blogs/ecommerce-app-img-2.png"),
    BlogEcommerce3:require("../../../assets/images/blogs/ecommerce-app-img-3.png"),
    BlogEcommerce4:require("../../../assets/images/blogs/ecommerce-app-img-4.png"),
    BlogWebDevelopment:require("../../../assets/images/blogs/web-dev-app.jpg"),
    BlogWebDevelopment1:require("../../../assets/images/blogs/web-dev-img1.jpg"),
    BlogWebDevelopment2:require("../../../assets/images/blogs/web-dev-img2.jpg"),
    BlogWebDevelopment3:require("../../../assets/images/blogs/web-dev-img3.jpg"),
    BlogWebDevelopment4:require("../../../assets/images/blogs/web-dev-img4.png"),
    BlogWebDevelopment5:require("../../../assets/images/blogs/web-dev-img5.png"),
    BlogWebDevelopment6:require("../../../assets/images/blogs/web-dev-img6.jpg"),
}
const CAREER = {
    arrow:require("../../../assets/images/arrow-up-white.png"),
}
const SEO = {
    DigitalMarketingDiscussion:require("../../../assets/images/digital-marketing-discussion.png"),
    SeoServiceBg:require("../../../assets/images/header-bg-half.png"),
    SeoGirl:require("../../../assets/images/seo-girl.png"),
    Elements:require("../../../assets/images/elements.png"),
    Settings:require("../../../assets/images/settings.png"),
    RootDirectory:require("../../../assets/images/root-directory.png"),
    Review:require("../../../assets/images/review.png"),
    WebsiteContent:require("../../../assets/images/website-content.png"),
    Backlinks:require("../../../assets/images/backlinks.png"),
    TwoManDiss:require("../../../assets/images/two-man-diss.jpg"),
    ThreeGirlDisc:require("../../../assets/images/three-girl-disc.png"),
}
const TRIP = {
    HailHimalayas: require("../../../assets/images/hail-himalayas.jpg"),
    HailHimalayasActivities: require("../../../assets/images/hail-himalayas-activities.jpg"),
    HailHimalayasDinningArea: require("../../../assets/images/hail-himalayas-dinning-area.jpg"),
    WaterFallImg1: require("../../../assets/images/water-fall-img-1.jpg"),
    WaterFallImg2: require("../../../assets/images/water-fall-img-2.jpg"),
    WaterFallImg3: require("../../../assets/images/water-fall-img-3.jpg"),
    WaterFallImg4: require("../../../assets/images/water-fall-img-4.jpg"),
    WaterFallImg5: require("../../../assets/images/water-fall-img-5.jpg"),
    WaterFallImg6: require("../../../assets/images/water-fall-img-6.jpg"),
    WaterFallImg7: require("../../../assets/images/water-fall-img-7.jpg"),
    WaterFallImg8: require("../../../assets/images/water-fall-img-8.jpg"),
    TheTripBegins: require("../../../assets/images/the-trip-begins.jpg"),
    GeekyFun1: require("../../../assets/images/geeky-fun-1.png"),
    GeekyFun2: require("../../../assets/images/geeky-fun-2.png"),
    GeekyFun3: require("../../../assets/images/geeky-fun-3.png"),
    GeekyFun4: require("../../../assets/images/geeky-fun-4.png"),
    GeekyFun5: require("../../../assets/images/geeky-fun-5.png"),
    Anniversary4: require("../../../assets/video/anniversary-4.mp4"),
    anniversary4Cake: require("../../../assets/images/anniversary-4.jpg"),
    AnniversaryCakeCutting: require("../../../assets/images/4-anniversary-cake-cutting.jpg"),
    OfficeAnniversary: require("../../../assets/images/office-anniversary.jpg"),
    AugCelebration: require("../../../assets/images/15-aug-celebration.jpg"),
    TeamGroup: require("../../../assets/images/team-group.jpg"),
    TeamParty: require("../../../assets/images/team-party.jpg"),
}
const WEBDEVELOPMENT = {
    GirlWithRedBg: require("../../../assets/images/girl-with-red-bg.png"),
    Messenger: require("../../../assets/images/messenger.png"),
    Whatsapp: require("../../../assets/images/whatsapp.png"),
    SkypeGrey: require("../../../assets/images/skype-grey.png"),
    Gmail: require("../../../assets/images/gmail.png"),
    NodeJs: require("../../../assets/images/nodejs.png"),
    SymfonyBlack: require("../../../assets/images/symfony-black.png"),
    Php: require("../../../assets/images/php.png"),
    React: require("../../../assets/images/react.png"),
    JavaWd: require("../../../assets/images/java-wd.png"),
    Kotlin: require("../../../assets/images/kotlin.png"),
    Upwork: require("../../../assets/images/up-work.png"),
    Freelancer: require("../../../assets/images/freelancer.png"),
}
const APPDETAILS  = {
    YellowMan: require("../../../assets/images/logo-yellow-man.webp"),
    Mobile1: require("../../../assets/images/mobile-1.webp"),
    Mobile2: require("../../../assets/images/mobile-2.webp"),
    OrderFood: require("../../../assets/images/order-food.png"),
    SmartPhone: require("../../../assets/images/smartphone.png"),
    AppDetailsBg: require("../../../assets/images/app-details-bg.jpg"),
    AppDetails: require("../../../assets/images/app-details.png"),
    NodeJsImg:require("../../../assets/images/nodejs.png"),
    SymfonyImg:require("../../../assets/images/symfony-services.png"),
    PhpImg:require("../../../assets/images/php.png"),
    ReactImg:require("../../../assets/images/react.png"),
    KotlinImg:require("../../../assets/images/kotlin.png"),
    JavaImg:require("../../../assets/images/java-wd.png"),
    MavrikProject:require("../../../assets/images/mavrik-project-bg.png"),
    AuslankmatrimonyProject:require("../../../assets/images/auslankmatrimony-project-bg.png"),
    SportGearWesite:require("../../../assets/images/sport-gear-website.jpg"),
    AllInProject:require("../../../assets/images/all-in-project.png"),
    SportGearProject:require("../../../assets/images/sport-gear-project.png"),
    FreshyProject:require("../../../assets/images/freshy-project.png"),
    CandyCoinProject:require("../../../assets/images/candycoin-project.png"),
    TadPoleProject:require("../../../assets/images/tadpole-project.png"),
    Aws:require("../../../assets/images/aws.png"),
    ElasticSearch:require("../../../assets/images/elastic-search.png"),
    FabricJs:require("../../../assets/images/fabric.png"),
    MySql:require("../../../assets/images/mysql.png"),
    Wordpress:require("../../../assets/images/wordpress-logo-2.png"),
    Gutenberg:require("../../../assets/images/Gutenberg-logo.png"),
    NextJs:require("../../../assets/images/next-js.png"),
    GraphQl:require("../../../assets/images/graph-ql.png"),
    ExpressJs:require("../../../assets/images/Express-js.png"),
    PostreSql:require("../../../assets/images/PostgreSQL-Logo.png"),
    SportBaseball:require("../../../assets/images/sport-baseball.png"),
    SportVolleyball:require("../../../assets/images/sport-volleyball.png"),
    SportFootball:require("../../../assets/images/sport-football.png"),
    SportBasketball:require("../../../assets/images/sport-basketball.png"),
    RestApi:require("../../../assets/images/rest-api.png"),
    SportHome:require("../../../assets/images/sport-home.png"),
    MavrikHome:require("../../../assets/images/mavrik-home.jpg"),
    MavrikAbout:require("../../../assets/images/mavrik-about.png"),
    MavrikCase:require("../../../assets/images/mavrik-case.png"),
    MavrikService:require("../../../assets/images/mavrik-service.png"),
    MavrikContact:require("../../../assets/images/mavrik-contact.png"),
    QualityApp1:require("../../../assets/images/quality-app-1.png"),
    QualityApp2:require("../../../assets/images/quality-app-2.png"),
    QualityApp3:require("../../../assets/images/quality-app-3.png"),
    QualityApp4:require("../../../assets/images/quality-app-4.png"),
    AllIn1:require("../../../assets/images/all-in-1.png"),
    AllIn2:require("../../../assets/images/all-in-2.png"),
    AllIn3:require("../../../assets/images/all-in-3.png"),
    AllIn4:require("../../../assets/images/all-in-4.png"),
    AllIn5:require("../../../assets/images/all-in-5.png"),
    Freshy1:require("../../../assets/images/freshy-1.png"),
    Freshy2:require("../../../assets/images/freshy-2.png"),
    Freshy3:require("../../../assets/images/freshy-3.png"),
    Freshy4:require("../../../assets/images/freshy-4.png"),
    GoLoader:require("../../../assets/images/go-loder.png"),
    Driver:require("../../../assets/images/driver.png"),
    VegatoApp1:require("../../../assets/images/vegato-1.png"),
    VegatoApp2:require("../../../assets/images/vegato-2.png"),
    VegatoApp3:require("../../../assets/images/vegato-3.png"),
    VegatoBanner:require("../../../assets/images/vegato-banner.png"),
    MeetingManagmentBanner:require("../../../assets/images/360-meeting-managment-banner.png"),
    RxTroBanner:require("../../../assets/images/rx-tro-banner.png"),
    TadpoleBanner:require("../../../assets/images/tadpole-banner.png"),
    FreshyBanner:require("../../../assets/images/freshy-banner.png"),
    SideffectBanner:require("../../../assets/images/sideffect-banner.png"),
    MeetingManagmentSlider:require("../../../assets/images/meeting-managment-1.png"),
    MeetingManagmentSlider2:require("../../../assets/images/meeting-managment-2.png"),
    MeetingManagmentSlider3:require("../../../assets/images/meeting-managment-3.png"),
    MeetingManagmentSlider4:require("../../../assets/images/meeting-managment-4.png"),
    FreshySlider1:require("../../../assets/images/freshy-mobile-1.png"),
    FreshySlider2:require("../../../assets/images/freshy-mobile-2.png"),
    FreshySlider3:require("../../../assets/images/freshy-mobile-3.png"),
    FreshySlider4:require("../../../assets/images/freshy-mobile-4.png"),
    TadpoleMobile1:require("../../../assets/images/tadpole-mobile-1.png"),
    TadpoleMobile2:require("../../../assets/images/tadpole-mobile-2.png"),
    TadpoleMobile3:require("../../../assets/images/tadpole-mobile-3.png"),
    TadpoleMobile4:require("../../../assets/images/tadpole-mobile-4.png"),
    RxTroMobile1:require("../../../assets/images/rxtro-mobile-1.png"),
    RxTroMobile2:require("../../../assets/images/rxtro-mobile-2.png"),
    RxTroMobile3:require("../../../assets/images/rxtro-mobile-3.png"),
    RxTroMobile4:require("../../../assets/images/rxtro-mobile-4.png"),
    SideffectMobile1:require("../../../assets/images/sideffect-mobile-1.png"),
    SideffectMobile2:require("../../../assets/images/sideffect-mobile-2.png"),
    SideffectMobile3:require("../../../assets/images/sideffect-mobile-3.png"),
    VagetoMobile1:require("../../../assets/images/vageto-mobile-1.png"),
    VagetoMobile2:require("../../../assets/images/vageto-mobile-2.png"),
    VagetoMobile3:require("../../../assets/images/vageto-mobile-3.png"),
    CandyCoin1:require("../../../assets/images/candy-1.png"),
    CandyCoin2:require("../../../assets/images/candy-2.png"),
    CandyCoin3:require("../../../assets/images/candy-3.png"),
    QualityMindBanner:require("../../../assets/images/quality-mind-banner.png"),
    AuslankamatrimonyWebsite1:require("../../../assets/images/auslankamatrimony-website-1.png"),
    AuslankamatrimonyWebsite2:require("../../../assets/images/auslankamatrimony-website-2.png"),
    AuslankamatrimonyWebsite3:require("../../../assets/images/auslankamatrimony-website-3.png"),
    TadpoleRidesWebsite:require("../../../assets/images/tadpole-website.png"),
}

export const ASSETS = {
    COMMON,
    HOME,
    ABOUT,
    SERVICES,
    PORTFOLIO,
    CONTACTUS,
    BLOG,
    CAREER,
    SEO,
    TRIP,
    WEBDEVELOPMENT,
    APPDETAILS
};
